<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header class="pb-3">
        <h1>Gestion des nouveautés</h1>
      </header>

      <LocalSwitcher
        @updateCurrentLocale="updateCurrentLocale($event)"
      ></LocalSwitcher>

      <div>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="submit"
        >
          <p v-if="successMessage">
            <v-alert
              text
              dense
              color="teal"
              border="left"
              type="success"
            >
              {{ successMessage }}
            </v-alert>
          </p>

          <p v-if="errors.length">
            <v-alert
              text
              dense
              type="error"
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error.title }}
            </v-alert>
          </p>

          <v-text-field
            class="mb-2"
            label="Title"
            v-model="title[currentLocale]"
            required
            :rules="[(v) => !!v] || 'Title est requis'"
          ></v-text-field>

          <v-row>

            <span>Release date</span>
            <datetime format="YYYY-MM-DD H:i:s" v-model='publishDate'></datetime>

            <br>

            <v-col
              cols="4"
            >
              <v-select
                :items="usersTypeOpt"
                v-model="userType"
                label="Type d'utilisateur"
                item-text="text"
                item-value="value"
                multiple
                :rules="[(v) =>  v.length>0] || 'Type d\'utilisateur est requis'"
                required
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 1"
                    class="grey--text text-caption"
                  >
                    <span>+ {{ userType.length-1 }}</span>
                  </span>
                </template>

              </v-select>
            </v-col>
          </v-row>

          <div class="tall_editor">
            <quill-editor
              ref="myQuillEditor"
              v-model="content[currentLocale]"
              :options="editorOption"
            />
          </div>

          <button type="submit" :disabled="!valid" class="btn btn-primary float-right mt-4">
            <i class="fas fa-check-circle"></i>Valider
          </button>

          <router-link
            to="/administration/gestion-des-nouveautes/"
            class="btn btn-danger float-right mt-4 mr-3 text-white"
          >
            <i class="fa fa-times"></i>
            <span>Cancel</span>
          </router-link>

        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Vue from 'vue'
import LocalSwitcher from '@/components/LocalSwitcher.vue'
import datetime from 'vuejs-datetimepicker'

export default {
  name: 'Releaselog',
  components: { LocalSwitcher, quillEditor, datetime },
  data: () => ({
    valid: false,
    successMessage: null,
    errors: [],
    loading: false,
    editorOption: {},
    menu: false,
    menu2: false,
    currentLocale: 'fr',
    id: [],
    title: [],
    content: [],
    userType: [],
    publishDate: null,
    usersTypeOpt: [
      { text: 'Salariés', value: 'e' },
      { text: 'Clients', value: 'c' },
      { text: 'Code budget', value: 'b' }
    ],
    entityData: {
      id: null,
      currentLocale: 'fr',
      defaultLocale: 'fr',
      newTranslations: {},
      publishDate: new Date()
    }
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - Gestion des nouveautés'
    if (this.$route.params.slug !== '0') {
      this.getReleaselog(this.$route.params.slug)
    }
  },
  methods: {
    getReleaselog: function (slug) {
      Vue.prototype.$http
        .get(config.apiUrl + '/releaselogs/' + slug, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          const releaselog = response.data
          const translations = releaselog.translations

          for (const [currentKey] of Object.entries(translations)) {
            let currentTranslation = translations[currentKey]

            this.id[currentKey] = currentTranslation.id
            this.title[currentKey] = currentTranslation.title
            this.content[currentKey] = currentTranslation.content
          }

          this.userType = releaselog.userType

          if (releaselog.publishDate != null && releaselog.publishDate !== '') {
            this.publishDate = releaselog.publishDate.replace('T', ' ').substring(0, releaselog.publishDate.lastIndexOf(':'))
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    submit: function () {
      this.$refs.form.validate()

      if (this.valid) {
        this.loading = true

        this.entityData.id = this.slug || this.$route.params.slug
        this.entityData.publishDate = this.publishDate
        this.entityData.userType = this.userType

        let method = 'PUT'
        let slug = '/' + this.entityData.id
        if (this.$route.params.slug === '0') {
          method = 'POST'
          slug = ''
        }

        const translations = [
          'title',
          'content'
        ]

        if (method === 'PUT') {
          translations.push('id')
        }

        this.handleTranslations(translations)

        if (method === 'POST') {
          delete this.entityData.id
        }

        Vue.prototype.$http
          .post(config.apiUrl + '/releaselogs' + slug, this.entityData, {
            headers: {
              Authorization: 'Bearer ' + this.$store.getters.getToken
            },
            params: {
              _method: method
            }
          })
          .then(response => {
            this.$router.push({ name: 'ReleaselogList' })
            this.loading = false
          })
          .catch(e => {
            const response = e.response
            this.loading = false
            this.errors = response.data.violations
            if (response.status === 400 || response.status === 404) {
              this.hasMasterKey = false
            }
            if (response.status === 403) {
              this.$store.dispatch('logout', 'rejected')
            }
          })
      }
    },
    handleTranslations (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        const currentAttribute = attributes[i]

        for (const [currentKey, currentValue] of Object.entries(this[currentAttribute])) {
          let translation = {
            locale: currentKey,
            empty: false
          }

          translation[currentAttribute] = currentValue

          const currentObjState = this.entityData.newTranslations[currentKey]

          this.entityData.newTranslations[currentKey] = { ...currentObjState, ...translation }
        }
      }
    },
    updateCurrentLocale (locale) {
      this.currentLocale = locale
      this.successMessage = null
    }
  }
}
</script>

<style>
.tall_editor .ql-container {
  min-height: 500px;
  background: white;
}
.v-chip.v-size--default {
  height: 25px;
}
</style>
